import React from "react";
import { graphql, getModule } from "gatsby";
import Page from "../components/commons/Page";
import Hero from "../components/commons/Hero";
import Flex from "../components/commons/Flex";

const GenericPage = ({ data: { sanityPage } }) => {
  return (
    <Page
      title={sanityPage.seoTitle || sanityPage.title || sanityPage.heroTitle}
      description={sanityPage.seoDescription}
      pathname={sanityPage.slug.current}
    >
      <Hero>
        <Hero.Content>
          <Flex
            sx={{
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Hero.Title>{sanityPage.heroTitle || sanityPage.title}</Hero.Title>
            {sanityPage.subtitle && (
              <Hero.Subtitle>{sanityPage.subtitle}</Hero.Subtitle>
            )}
          </Flex>
        </Hero.Content>
        {sanityPage.heroImage?.asset && (
          <Hero.Illustration>
            <img
              sizes={sanityPage.heroImage.asset.fluid.sizes}
              srcset={sanityPage.heroImage.asset.fluid.srcSet}
              loading="eager"
              alt={sanityPage.heroImage.alt}
            />
          </Hero.Illustration>
        )}
      </Hero>
      {sanityPage.blocks.map((block) => {
        const Component = getModule(block.component);

        if (!Component) {
          return <span>loading...</span>;
        }

        return <Component {...block} />;
      })}
    </Page>
  );
};

export default GenericPage;

export const pageQuery = graphql`
  query GenericPage($sanityId: String!) {
    sanityPage(_id: { eq: $sanityId }) {
      title
      heroTitle
      subtitle
      seoTitle
      seoDescription
      slug {
        current
      }
      heroImage {
        alt
        asset {
          fluid(maxWidth: 600) {
            ...sanityImageFluidNoWebP
          }
        }
      }
      blocks {
        ...Blocks
      }
    }
  }
`;
